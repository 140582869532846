@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
.auth-box {
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    margin-top: 118px;
}

.auth-title {
    font-weight: 600;
    font-size: 25px;
    line-height: 154.84%;
    color: #575757;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 21px;
}

.hr-auth {
    color: #E2E2E2;
}


.auth-input {
    background: #EDEDED;
    border-radius: 10px;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    border: none;
    padding: 12px 27px 13px 27px;
    width: 80%;
    margin: 0 auto 14px auto;
    display: block;
}

.auth-rm {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.auth-rm label {
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
}

.auth-login-btn {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: #FFB40D;
    border-radius: 10px;
    border: none;
    padding: 12px 148px;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
}

.auth-form {
    padding-top: 25px;
    padding-bottom: 21px;
}

.auth-form-box {
    margin-top: 2rem;
    margin-bottom: 10rem;
}
.itemText {
    white-space: pre-line;
}

.indicator {
    font-size: 14px;
    line-height: 133%;
    color: #575757;
}

.itemTitle {
    font-size: 24px;
    font-size: 24px;
    line-height: 133%;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 24px;
}

.seconds {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #F77700;
}

.dismissText {
    margin-top: 18px;
    margin-bottom: 40px;
}

.label {
    font-weight: 600;
    font-size: 16px;
    line-height: 133%;
    color: #000000;
}

.sendBtn {
    border: none;
    border-radius: 50%;
    background: #979797;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 14px;
    cursor: pointer;
}

.sendBtn.ok {
    background: #66D322;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
}

.qhr {
    color: rgba(0, 0, 0, 0.21);
    margin-top: 77px;
    margin-bottom: 40px;
}

.qae {
    font-weight: bold;
    font-size: 20px;
    line-height: 133%;
    color: #000000;
}

.qac {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #F77700;}

.qac.ok {
    color: #66D322;
}

.scbtnarea {
    margin-top: 40px;
    margin-bottom: 110px;
    text-align: center;
}

.scbtn {
    background: #F7B500;
    box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    border: none;
    padding: 15px 60px;
    height: 49px;

    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.scbtn:disabled {
    background: lightgray;
    cursor: not-allowed;
}

.scbtn:focus {
    outline: none;
}

.tqtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 133%;
    color: #000000;
}

.tq-box1, .tq-box2 {
    background: #EEEEEE;
    border-radius: 6px;
    padding: 17px;
    height: 98px;
    overflow-y: auto;
    margin-bottom: 18px;
}

.tq-box1 {
    width: 95%;
}

.tq-box {
    position: relative;
}

.delbtn {
    position: absolute;
    right: 20px;
    top: 40%;
    cursor: pointer;
    background-color: #E50123;
    color: #ffffff;
    border-radius: 50%;
    border: none;
    padding: 5px;
    font-size: 10px;
}
.users-select {
    width: 300px;
}

.source-select {
    color: hsl(0,0%,50%);
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 38px;
    margin-right: 4px;
}
.navbar-class {
    background-color: #FFFFFF;
    height: 97px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
}

.logoutbtn {
    font-weight: bold;
    font-size: 16px;
    line-height: 133%;
    color: #332B3F;
}

.username {
    font-size: 16px;
    line-height: 133%;
    text-transform: lowercase;
    color: #332B3F;
}

.logoText {
    font-size: 14px;
    line-height: 133%;
    text-transform: capitalize;
    color: #000000;
    margin-top: 8px;
}

.instructions {
    margin-top: 68px !important;
}

.instructions .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 133%;
    color: #3745A5;
}

.instructions p {
    font-size: 24px;
    line-height: 133%;
    text-align: justify;
    margin-bottom: 16px !important;
}

.instructions hr {
    border: 1px solid rgba(0, 0, 0, 0.21);
}

.totalQuestions {
    float: right;
    font-weight: bold;
    font-size: 18px;
    color: green;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
  
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #2196F3;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
  
input:checked + .slider:before {
    transform: translateX(26px);
}
  
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

.sub-title {
    font-weight: bold;
    font-size: 16px;
    color: #3745A5; 
}

.unansquesp {
    font-size: 14px !important;
    line-height: normal !important;
    line-height: initial !important;
}

*:focus {
    outline: none;
}

body {
    background-color: #f7f8fa;
}

.row {
    margin: 0;
    padding: 0;
}
