@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');

*:focus {
    outline: none;
}

body {
    background-color: #f7f8fa;
}

.row {
    margin: 0;
    padding: 0;
}