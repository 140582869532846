.navbar-class {
    background-color: #FFFFFF;
    height: 97px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
}

.logoutbtn {
    font-weight: bold;
    font-size: 16px;
    line-height: 133%;
    color: #332B3F;
}

.username {
    font-size: 16px;
    line-height: 133%;
    text-transform: lowercase;
    color: #332B3F;
}

.logoText {
    font-size: 14px;
    line-height: 133%;
    text-transform: capitalize;
    color: #000000;
    margin-top: 8px;
}

.instructions {
    margin-top: 68px !important;
}

.instructions .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 133%;
    color: #3745A5;
}

.instructions p {
    font-size: 24px;
    line-height: 133%;
    text-align: justify;
    margin-bottom: 16px !important;
}

.instructions hr {
    border: 1px solid rgba(0, 0, 0, 0.21);
}

.totalQuestions {
    float: right;
    font-weight: bold;
    font-size: 18px;
    color: green;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
  
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #2196F3;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

.sub-title {
    font-weight: bold;
    font-size: 16px;
    color: #3745A5; 
}

.unansquesp {
    font-size: 14px !important;
    line-height: initial !important;
}
