.users-select {
    width: 300px;
}

.source-select {
    color: hsl(0,0%,50%);
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 38px;
    margin-right: 4px;
}